<template>
  <span class="set-flex  n-wrap set-flex-y-center n-wrap">
    <span class="status-cell">
      {{ $t( 'gifts.reject' ) }}
    </span>
    <span
      v-if="showIcon"
      class="rbi-down-arrow"
    />
  </span>
</template>

<script>
export default {
  props: {
    showIcon: Boolean,
  },
};
</script>
