<template>
  <component
    :is="status"
    :show-icon="showIcon"
  >
    <span slot="link">link</span>
  </component>
</template>

<script>
import packaging from './packaging.vue';
import inProgress from './inProgress.vue';
import rejected from './reject.vue';
import success from './success.vue';
import payed from './payed';
import notpayed from './notpayed';

export default {
  components: {
    packaging,
    on_way: inProgress,
    rejected,
    accept: success,
    not_payed: notpayed,
    payed,
  },
  props: {
    status: String,
    showIcon: Boolean,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style></style>
