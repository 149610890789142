<template>
  <span class="set-flex n-wrap set-flex-y-center">
    <span class="status-cell">
      {{ $t('gifts.payed') }}
    </span>
    <span class="rbi-check" />
  </span>
</template>

<script>
export default {
  name: 'Payed',

  props: {
    showIcon: Boolean,
  },
  computed: {
    sex() {
      return this.$store.getters.sex;
    },
  },
};
</script>

<style scoped></style>
