<template>
  <div class="col-lg-10 col-md-12 gift-page main-block">
    <div class="white-box action-page">
      <div class="action-page--heading">
        <div class="d-flex justify-content-between">
          <h1 class="action-page--heading__h d-flex align-items-center">
            <span>
              {{ $t('actionPages.titles.gifts') }}
            </span>
          </h1>
          <div class="action-page--tip">
            <form class="orders-search d-flex align-items-center" @submit.prevent="">
              <div class="icon">
                <span class="rbi rbi-filter" />
              </div>
              <div class="form-group">
                <input
                  v-model="searchName"
                  type="text"
                  :placeholder="$t('shared.placeholders.nameId')"
                  class="form-control"
                />
              </div>

              <div class="form-group">
                <multiselect
                  v-model="searchType"
                  :allow-empty="false"
                  :options="options"
                  :searchable="false"
                  track-by="k"
                  label="v"
                  :show-labels="false"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="action-page--content">
        <div v-show="displayedGifts.length" class="order-table--wrap">
          <table class="order-table gift-table">
            <tr class="d-none d-lg-table-row order-table--header order-table--row">
              <td
                class="order-table--cell order-table--header__cell small-cell d-none d-lg-table-cell"
              >
                <div class="relative">
                  {{ $t('actionPages.utils.serialNumber') }}
                  <button type="button" class="btn-sort" @click="sortBySerial">
                    <span class="rbi rbi-sort" />
                  </button>
                </div>
              </td>
              <td
                class="order-table--cell order-table--header__cell large-cell d-none d-lg-table-cell"
              >
                {{ $t('actionPages.utils.gift') }}
                <button type="button" class="btn-sort" @click="sortByName">
                  <span class="rbi rbi-sort" />
                </button>
              </td>
              <td
                class="order-table--cell order-table--header__cell small-cell d-none d-lg-table-cell"
              >
                <div class="relative">
                  {{ $t('actionPages.utils.quantityS') }}
                  <button type="button" class="btn-sort" @click="sortByQuantity">
                    <span class="rbi rbi-sort" />
                  </button>
                </div>
              </td>
              <td
                class="order-table--cell order-table--header__cell large-cell d-none d-lg-table-cell"
              >
                <div class="relative">
                  {{ $t('actionPages.utils.sender') }}
                  <button type="button" class="btn-sort" @click="sortBySender">
                    <span class="rbi rbi-sort" />
                  </button>
                </div>
              </td>
              <td class="order-table--cell order-table--header__cell responsive-width">
                <div class="relative">
                  {{ $t('actionPages.utils.status') }}
                  <button type="button" class="btn-sort" @click="sortByStatus">
                    <span class="rbi rbi-sort" />
                  </button>
                </div>
              </td>
              <td
                class="order-table--cell order-table--header__cell large-cell d-none d-lg-table-cell"
              >
                <div class="relative">
                  {{ $t('actionPages.utils.date') }}
                  <button type="button" class="btn-sort" @click="sortByTime">
                    <span class="rbi rbi-sort" />
                  </button>
                </div>
              </td>
              <td
                class="order-table--cell order-table--header__cell text-center bdtop-r d-table-cell d-lg-none"
              >
                <span class="rbi rbi-sort" />
              </td>
            </tr>
            <template v-for="(item, index) in limited">
              <tr
                :key="`tr-indexx-${index}`"
                :class="{ open: item.open }"
                class="body order-table--row visible-md visible-lg"
              >
                <td class="order-table--cell gift-cell d-none d-lg-table-cell">
                  {{ item.gift_send.id }}
                </td>
                <td class="order-table--cell gift-cell d-none d-lg-table-cell">
                  <div class="item-cell">
                    <div class="wrap">
                      <img
                        class="gift-image"
                        :src="setPhotoSource(item.gift.photo)"
                        :alt="item.gift.name"
                      />
                    </div>
                    <p class="wrap">
                      {{ item.gift.name }}
                    </p>
                  </div>
                </td>
                <td class="order-table--cell gift-cell d-none d-lg-table-cell">
                  {{ item.gift_send.amount }}
                </td>

                <td class="order-table--cell gift-cell d-none d-lg-table-cell">
                  <div class="item-cell">
                    <div class="wrap_img">
                      <img
                        :src="setPhotoSource(item.gift_send.sender.avatar.small)"
                        :alt="item.gift_send.sender.name + $t('shared.alts.ava')"
                      />
                    </div>
                    <div class="wrap">
                      <p>{{ item.gift_send.sender.name }}</p>
                      <p>ID: {{ item.gift_send.sender.user_id }}</p>
                    </div>
                  </div>
                </td>
                <td class="order-table--cell gift-cell d-none d-lg-table-cell">
                  <status-template
                    :class="{
                      delivered: item.status === 'accept',
                      open: item.open,
                      reject: item.status === 'rejected',
                    }"
                    :status="item.status"
                    :show-icon="false"
                  />
                  <a
                    v-if="item.status === 'accept' && item.gift_send.comment"
                    href="#"
                    class="toggle-letter"
                    @click.prevent="openLetter(item)"
                    >{{ $t('actionPages.utils.letter') }}</a
                  >
                </td>
                <td class="order-table--cell gift-cell d-none d-lg-table-cell">
                  {{ displayDate(item.gift_send.time_create) }}
                </td>
              </tr>
              <tr :key="`tr-indexy-${index}`">
                <td colspan="6" class="mask-tr">
                  <div class="responsive-gift-item d-block d-lg-none">
                    <div class="flex-row responsive-gift-item__header">
                      <div class="gift-info-item d-flex">
                        <div class="item">
                          <img
                            class="gift-image"
                            :src="setPhotoSource(item.gift.photo)"
                            :alt="item.gift.name"
                          />
                        </div>
                        <div class="item">
                          <p>{{ item.gift.name }}</p>
                        </div>
                      </div>

                      <div class="serial-info-item">
                        <div class="item">
                          <span class="text-black"
                            >{{ $t('actionPages.utils.serialNumber') }}:
                          </span>
                          <span>{{ item.gift_send.id }}</span>
                        </div>
                        <div class="item">
                          <span class="text-black">{{ $t('actionPages.utils.date') }}:</span>
                          <span>{{ displayDate(item.gift_send.time_create) }}</span>
                        </div>
                      </div>
                    </div>
                    <div v-if="item.open" class="flex-row bordered">
                      <div class="receiver-info-item">
                        <div class="wrap_img">
                          <img
                            :src="setPhotoSource(item.gift_send.sender.avatar.small)"
                            :alt="item.gift_send.sender.name"
                          />
                        </div>
                        <div class="col">
                          <p>{{ item.gift_send.sender.name }}</p>
                          <p>ID: {{ item.gift_send.sender.user_id }}</p>
                        </div>
                      </div>
                      <div class="price-info-item">
                        <div class="item">
                          <p class="text-black">Q-ty:</p>
                          <p>x {{ item.gift_send.amount }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="flex-row delivery-row">
                      <div class="item">
                        <p>{{ $t('actionPages.utils.status') }}:</p>
                        <p class="status d-flex">
                          <status-template
                            :status="item.status"
                            class="resp-status"
                            :show-icon="false"
                          />
                          <template v-if="item.status === 'accept' && item.gift_send.comment">
                            |
                            <a href="#" class="toggle-letter" @click.prevent="openLetter(item)"
                              >letter</a
                            >
                          </template>
                        </p>
                      </div>
                      <div
                        :class="{ opened: item.open }"
                        class="item more"
                        @click="item.open = !item.open"
                      >
                        <span>{{ $t('actionPages.utils.more') }}</span>
                        <span class="rbi rbi-down-arrow" />
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
          </table>
        </div>
        <div v-show="isSearching && !searchResult.length" class="alert-actions-wrap">
          <p class="alert alert-warning">
            {{ $t('actionPages.errors.noSearchResults') }}
          </p>
        </div>
        <div
          v-show="gifts.length < 1 && preloadPage === false && !isSearching"
          class="alert-actions-wrap"
        >
          <div class="alert alert-info">
            <p>{{ $t('actionPages.errors.noGifts') }}</p>
          </div>
        </div>
        <div v-show="preloadPage" class="alert-actions-wrap">
          <div class="loader--wrap">
            <div class="loader" />
          </div>
        </div>
        <lazy-load v-show="displayLoadMoreBtn" :counter="20" @click.native="limit += limit" />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import statusTemplate from './myGifts/template.vue';
import { PHOTO_SRC } from '../../mixins/utils';
import LazyLoad from './parts/LoadMore.vue';

export default {
  components: {
    statusTemplate,
    LazyLoad,
  },
  mixins: [PHOTO_SRC],
  data() {
    return {
      limit: 30,
      preloadPage: true,
      options: [
        {
          k: '',
          v: this.$t('mail.sort.default'),
        },
        {
          k: 'packaging',
          v: this.$t('gifts.pack'),
        },
        {
          k: 'on_way',
          v: this.$t('gifts.progress'),
        },
        {
          k: 'accept',
          v: this.$t('gifts.success'),
        },
        {
          k: 'rejected',
          v: this.$t('gifts.reject'),
        },
      ],
      defaultLimit: 30,
      showStatus: false,
      selectResult: [],
      searchResult: [],
      nonCategory: {},
      gifts: [],
      searchType: { k: '', v: this.$t('mail.sort.default') },
      isSearching: false,
      searchName: '',
      sortState: {
        receiver: false,
        summ: false,
        quantity: false,
        action: false,
        id: false,
        serial: false,
        price: false,
        name: false,
        time: false,
        giftName: false,
      },
    };
  },
  computed: {
    /**
     * Показывать ли кнопку "загрузить еще"
     *
     * @return {boolean}
     */
    displayLoadMoreBtn() {
      if (this.isSearching) {
        return this.displayedGifts.length < this.searchResult.length;
      }
      return this.displayedGifts.length < this.gifts.length;
    },

    /**
     * Выбрать показываемые записи: результаты поиска или обычный список
     *
     * @return {array}
     */
    displayedGifts() {
      return this.isSearching ? this.searchResult.slice(0, this.limit) : this.gifts;
    },

    /**
     * Получить ограниченное кол-во показываемых подарков
     *
     * @return {array}
     */
    limited() {
      return this.displayedGifts.slice(0, this.limit);
    },
    sex() {
      return this.$store.getters.sex;
    },
  },
  watch: {
    /**
     * Поиск среди пользователей по имени или ИД
     *
     * @param val {string}
     */
    searchName(val) {
      if (val.length) {
        this.isSearching = true;
        const self = this;
        setTimeout(() => {
          self.search();
        });
      } else {
        this.searchResult = [];
        this.isSearching = false;
      }
    },

    /**
     * Отфильтровать данные по имени получателя или его ИД
     *
     * @param val
     */
    searchType(val) {
      if (val.k) {
        this.isSearching = true;
        const self = this;
        setTimeout(() => {
          self.search();
        });
      } else {
        this.searchResult = [];
        this.isSearching = false;
      }
    },
  },
  mounted() {
    this.getFromServer();
    document.title = this.$t('actionPages.titles.gifts');
  },
  methods: {
    /**
     * Узнать статус доставки, основываясь на статусах агенства и сервиса
     *
     * @param agency {string}
     * @param site {string}
     * @return {string}
     */
    compareStatus(agency, site) {
      if (agency === 'free' && agency === site) {
        return 'packaging';
      }
      if (agency === 'reject') {
        return 'rejected';
      }
      if (site === 'reject') {
        return 'on_way';
      }
      if (agency === 'accept' && agency === site) {
        return 'accept';
      }

      if (agency === 'accept' && site !== 'reject') {
        return 'on_way';
      }
    },

    /**
     * Открыть сопроводительное письмо
     *
     * @param item
     */
    openLetter(item) {
      this.$store.commit('updateGiftLetterModal', {
        open: true,
        user: item.gift_send.sender,
        text: item.gift_send.comment,
      });
    },

    /**
     * Отсортировать данные по серийному номеру
     */
    sortBySerial() {
      this.sortState.serial = !this.sortState.serial;
      const self = this;
      const sort = function (a, b) {
        if (self.sortState.serial) {
          return a.gift_send.id - b.gift_send.id;
        }
        return a.gift_send.id > b.gift_send.id ? -1 : 1;
      };
      this.displayedGifts.sort(sort);
    },
    /**
     * Отсортировать данные по имени
     */
    sortByName() {
      const self = this;
      this.sortState.name = !this.sortState.name;
      const sort = function (a, b) {
        const textA = a.gift.name.toLowerCase().trim();
        const textB = b.gift.name.toLowerCase().trim();
        if (self.sortState.name) {
          return textA > textB ? 1 : -1;
        }
        return textA > textB ? -1 : 1;
      };
      this.displayedGifts.sort(sort);
    },
    /**
     * Отсортировать данные по цене
     */
    sortByPrice() {
      this.sortState.price = !this.sortState.price;
      const self = this;
      const sort = function (a, b) {
        if (self.sortState.price) {
          return a.gift.price - b.gift.price;
        }
        return a.gift.price > b.gift.price ? -1 : 1;
      };
      this.displayedGifts.sort(sort);
    },
    /**
     * Отсортировать данные по кол-ву
     */
    sortByQuantity() {
      this.sortState.quantity = !this.sortState.quantity;
      const self = this;
      const sort = function (a, b) {
        if (self.sortState.quantity) {
          return a.gift_send.amount - b.gift_send.amount;
        }
        return a.gift_send.amount > b.gift_send.amount ? -1 : 1;
      };
      this.displayedGifts.sort(sort);
    },
    /**
     * Отсортировать данные по сумме кредитов
     */
    sortBySum() {
      this.sortState.summ = !this.sortState.summ;
      const self = this;

      const sort = function (a, b) {
        const first = a.gift_send.amount * a.gift.price;
        const second = b.gift_send.amount * b.gift.price;
        if (self.sortState.summ) {
          return first - second;
        }
        return first > second ? -1 : 1;
      };
      this.displayedGifts.sort(sort);
    },
    /**
     * Отсортировать данные по отправителю
     */
    sortBySender() {
      const self = this;
      this.sortState.receiver = !this.sortState.receiver;
      const sort = function (a, b) {
        const textA = a.gift_send.sender.name.toUpperCase();
        const textB = b.gift_send.sender.name.toUpperCase();
        if (self.sortState.receiver) {
          return textA > textB ? 1 : -1;
        }
        return textA > textB ? -1 : 1;
      };
      this.displayedGifts.sort(sort);
    },
    /**
     * Отсортировать данные по статусу доставки
     */
    sortByStatus() {
      const self = this;
      this.sortState.status = !this.sortState.status;
      const sort = function (a, b) {
        const textA = a.status.toUpperCase();
        const textB = b.status.toUpperCase();
        if (self.sortState.status) {
          return textA > textB ? 1 : -1;
        }
        return textA > textB ? -1 : 1;
      };
      this.displayedGifts.sort(sort);
    },
    /**
     * Отсортировать данные по времени создания заказа
     */
    sortByTime() {
      this.sortState.time = !this.sortState.time;
      const self = this;
      const sort = function (a, b) {
        if (self.sortState.time) {
          return a.gift_send.time_create - b.gift_send.time_create;
        }
        return a.gift_send.time_create > b.gift_send.time_create ? -1 : 1;
      };
      this.displayedGifts.sort(sort);
    },
    /**
     * Получить понятное представление даты о времени создания заказа
     */
    getCreatedDate(timestamp) {
      return moment(timestamp * 1000).format('MMM Do, YYYY');
    },
    /**
     * Получить понятное представление даты о времени доставки заказа
     */
    getDeliveredDate(timestamp) {
      return moment(timestamp * 1000).format('MMM Do, YYYY');
    },
    /**
     * Открыть детальный просмотр инфы о заказе
     */
    openStatus(item) {
      item.open = true;
    },
    /**
     * Отсортировать данные по категории
     */
    sortByCategory() {
      if (this.isSearching) {
        this.selectResult = this.searchResult.filter((item) => item.status === this.searchType);
      } else {
        this.selectResult = this.gifts.filter((item) => item.status === this.searchType);
      }
    },
    /**
     * Поиск по имени или ИД среди отправителей
     */
    search() {
      let name = this.searchName;
      name = name.toLowerCase();
      this.limit = this.defaultLimit;
      if (this.searchName.length && this.searchType.k.length) {
        this.searchResult = this.gifts.filter((item) => {
          const id = `${item.gift_send.sender.user_id}`;
          if (
            item.gift_send.sender.name.toLowerCase().includes(name) ||
            (id.includes(name) && item.status === this.searchType.k)
          ) {
            return true;
          }
        });
        return;
      }
      if (this.searchName.length) {
        this.searchResult = this.gifts.filter((item) => {
          const id = `${item.gift_send.sender.user_id}`;
          if (item.gift_send.sender.name.toLowerCase().includes(name) || id.includes(name)) {
            return true;
          }
        });
      } else if (this.searchType.k.length) {
        this.searchResult = this.gifts.filter((item) => item.status === this.searchType.k);
      }
    },

    /**
     * Получить список подарков
     */
    getFromServer() {
      this.$http
        .post('v1/gift/my', {
          access_token: window.localStorage['access-token'],
        })
        .then((response) => {
          const outputArr = [];
          response.body.result.forEach((i) => {
            if (i.gift_send.payment_status !== 1) {
              i.open = false;
              i.status = this.compareStatus(
                i.gift_send.moderation_agency,
                i.gift_send.moderation_site,
                i.gift_send.payment_status
              );
              outputArr.push(i);
            }
          }, this);
          this.gifts = outputArr;
          /* this.gifts = response.body.result */
          this.preloadPage = false;
        });
    },
    /**
     * Получить строчное представление даты
     * @param timestamp {number}
     * @return {string}
     */
    displayDate(timestamp) {
      return moment(timestamp * 1000).format('MMM Do, YYYY');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/variables';

.body {
  .order-table--cell {
    height: 65px;
  }
}

.gift-cell {
  .item-cell {
    .wrap + .wrap {
      margin-left: 5px;
      overflow: hidden;
      white-space: nowrap;
      -ms-text-overflow: ellipsis;
      text-overflow: ellipsis;

      p {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.responsive-gift-item {
  .flex-row {
    display: flex;
    justify-content: space-between;
    padding: 10px;

    .receiver-info-item {
      display: flex;
      width: 50%;

      .col {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        padding-right: 10px;
      }

      .wrap_img {
        position: relative;
        margin-right: 1em;

        &:after {
          content: '';
          position: absolute;
          width: 53px;
          height: 53px;
          border: 1px solid transparent;
          border-right-color: $black;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
        }

        img {
          width: 45px;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
        }
      }
    }
  }

  .gift-info-item {
    flex: 0 0 50%;
    display: flex;
    white-space: normal;

    .item + .item {
      margin-left: 10px;
    }
  }

  .bordered {
    border-top: 1px solid #e3e3e3;
    .price-info-item {
      flex: 0 0 50%;
      display: flex;
      flex-direction: column;

      .item {
        display: flex;
        justify-content: space-between;
      }
    }
    .coin-icon {
      height: 18px;
      width: 18px;
    }
  }
}

.toggle-letter {
  color: $brand-primary;
  text-decoration: underline;
  font-size: 11px;
}

.resp-status {
  margin: 0 !important;

  + .toggle-letter {
    margin-left: 9px;
  }
}

.large-cell {
  width: 20%;
}

.small-cell {
  width: 10%;
}

.medium-cell {
  width: 15%;
}

.gift-info-item {
  .gift-image {
    width: 40px;
  }
  .item {
    display: flex;
    align-items: center;
  }
}

.item-cell {
  display: flex;
  align-items: center;
  .wrap {
    img {
      width: 40px;
    }
  }
  .wrap_img {
    position: relative;
    margin-right: 1em;

    &:after {
      content: '';
      position: absolute;
      width: 53px;
      height: 53px;
      border: 1px solid transparent;
      border-right-color: $black;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
    }

    img {
      width: 45px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
    }
  }
}

.responsive-gift-item {
  border: 1px solid #d9d9d9;
  margin-bottom: 25px;
  &__header {
    background: #f6f6f6;
  }

  .flex-row {
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;

    .serial-info-item {
      flex: 1 1;
      .item {
        display: flex;
        justify-content: space-between;
      }
    }

    .item {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      &.more {
        flex-wrap: nowrap;
        span {
          &:first-child {
            border-bottom: 1px dashed #000000;
          }
        }
        .rbi {
          margin-left: 0.3em;
        }
      }
      .status {
        margin-left: 0.3em;
        color: #e21919;
      }
    }
  }

  .delivery-row {
    border-top: 1px solid #d9d9d9;
  }
}

.text-black {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1.4px;
}

.icon {
  font-size: 20px;
}

.form-group {
  width: 194px;
  margin-left: 15px;

  .form-control {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .responsive-gift-item__header {
    flex-direction: column !important;
    .serial-info-item {
      margin-top: 20px;
    }
  }

  .responsive-gift-item .bordered {
    flex-direction: column !important;
    .price-info-item {
      margin-top: 20px;
    }
  }

  .icon {
    display: none;
  }

  .form-group {
    width: 48%;
    margin-left: 0;
  }

  .action-page--heading {
    > .d-flex {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }

    &__h {
      align-content: flex-start;
    }
    .action-page--tip {
      width: 100%;
      margin-top: 15px;

      .d-flex {
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }
}
</style>
