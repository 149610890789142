<template>
  <span class="set-flex n-wrap set-flex-y-center ">
    <span class="status-cell">
      {{ $t( 'gifts.pack' ) }}
    </span>
    <span
      v-if="showIcon"
      class="rbi-down-arrow"
    />
  </span>
</template>

<script>
export default {
  props: {
    showIcon: Boolean,
  },
};
</script>
